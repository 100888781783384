import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";
import {downloadBlob, getFileNameFromContentDisposition} from "../../utils/FileUtils";

export default class DownloadTemplateCommand extends FetchCommand<boolean, boolean> {
    async execute() {
        try {
            const response: Response = await api("/api/batch/template");

            if (!response.ok) {
                throw new Error(`Error downloading file: ${response.statusText}`);
            }

            let filename = getFileNameFromContentDisposition(response);
            const blob = await response.blob();
            downloadBlob(blob, filename);
        } catch (error) {
            console.error('Error:', error);
        }
        return true;
    }
}