export enum Pages {
    LANDING = "/app/landing",
    MODERATION = "/app/moderation",
    LOGIN = "/app/login",
    REGISTER_BUSINESS_ACCOUNT = "/app/register-business-account",
    USER_PROFILE = "/app/userProfile",
    CONVERSATION_DETAILS = "/app/conversationDetails/:conversationId",
    NEW_AD = "/app/newAd",
    SEARCH = "/app/search",
    DETAILED_SEARCH = "/app/detailedSearch",
    AD_PUBLIC_DETAILS = "/app/adDetails/:adId",
    EDIT_AD = "/app/editAd/:adId",
    ADMINISTRATION = "/app/administration",
    ADMINISTRATION_USER_DETAILS = "/app/administrationUserDetails/:userId",
    CREATE_PARTNER = "/app/administrationCreatePartner",
    PARTNER_DETAILS = "/app/partnerDetails/:partnerId",
    ABOUT = "/app/about",
    PUBLIC_CONTRACT = "/app/publicContract",
    PRIVACY = "/app/privacy",
    LEGAL_ASPECTS = "/app/legalAspects",
    COOKIES = "/app/cookies",
    DELETE_PERSONAL_DATA = "/app/deletePersonalData",
    PAYMENT_INFO = "/app/paymentInfo"
}