import {FC, useEffect, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {isMobile} from "../../../../../common/components/screen/Screen";
import {useScreenWidth} from "../../../../../common/hooks/useScreenWidth";
import {Pages} from "../../../../../routing/Pages";
import {ProfileMobileHeader} from "../../profileMobileHeader/ProfileMobileHeader";
import {ReactComponent as ChevronLeft} from "./resources/chevronLeft.svg";
import {SelectedConversation} from "../selectedConversation/SelectedConversation";
import {PublicUserInfo} from "../../../../../common/commands/user/types/PublicUserInfo";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {isEmpty, set} from "lodash-es";
import {GetPublicUserInfoCommand} from "../../../../../common/commands/user/GetPublicUserInfoCommand";
import {arrayToMap} from "../../../../../common/utils/ArrayUtils";
import {Spin} from "antd";
import "./styles/conversationDetails.scss";
import AdvertisementDecorator from "../../../../../types/decorators/AdvertisementDecorator";

export const ConversationDetails: FC = () => {
    const {state: {conversation, advertisement}} = useLocation();
    const navigate = useNavigate();
    const width = useScreenWidth();
    const [users, setUsers] = useState<Record<string, PublicUserInfo>>({});
    const [loading, setLoading] = useState(true);
    const decorator: ConversationDecorator = new ConversationDecorator(conversation);

    decorator.advertisement = new AdvertisementDecorator(advertisement);

    const [conversationItem, setConversation] = useState<ConversationDecorator | null>(decorator);
    const getAuthorIds = (conversations: ConversationDecorator[]): string[] => {
        return conversations.reduce((result: string[], item: ConversationDecorator) => {
            const {authorId = ""} = item.advertisement?.src ?? {};
            if (!users[authorId]) {
                result.push(authorId);
            }
            return result;
        }, []);
    };

    const to: string = `${Pages.USER_PROFILE}?tab=messages`;
    const handleClick = () => {
        navigate(to);
    };

    const loadData = async () => {
        try {
            setLoading(true);
            const authorIds: string[] = getAuthorIds([conversationItem!]);
            if (!isEmpty(authorIds)) {
                const loadedUsers: Record<string, PublicUserInfo> = await new GetPublicUserInfoCommand()
                    .execute(authorIds)
                    .then(arrayToMap<PublicUserInfo>);
                setUsers({...users, ...loadedUsers});
            }
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (conversation: ConversationDecorator) => {
        setConversation(conversation);
    };

    const handleDelete = () => {
        setConversation(null);
    };

    useEffect(() => {
        loadData();
    }, []);

    if (!isMobile(width)) {
        return (<Navigate to={to}/>);
    }

    return (
        <Spin spinning={loading} wrapperClassName="conversation-details">
            <ProfileMobileHeader>
                <button onClick={handleClick}>
                    <ChevronLeft/>
                    Усі повідомлення
                </button>
            </ProfileMobileHeader>
            <div>
                <SelectedConversation conversation={conversationItem} onUpdate={handleUpdate}
                                      users={users}
                                      onDelete={handleDelete}/>
            </div>
        </Spin>
    );
};