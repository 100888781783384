import {FC, LegacyRef, SyntheticEvent, useEffect, useRef, useState} from "react";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {Card, Comment} from "antd";
import {ConversationInfoRenderer} from "../conversationInfoRenderer/ConversationInfoRenderer";
import TextArea from "antd/es/input/TextArea";
import cn from "classnames";
import {Button, ButtonType} from "../../../../../common/components/button/Button";
import {ReactComponent as SendIcon} from "./resources/SendIcon.svg";
import {isEmpty} from "lodash-es";
import {MessageDecorator} from "../../../../../types/decorators/MessageDecorator";
import {useCurrentUser} from "../../../../../common/commands/user/useCurrentUser";
import AdNewMessageCommand from "./commans/AdNewMessageCommand";
import MarkAsReadConversationCommand from "./commans/MarkAsReadConversationCommand";
import {DeleteConversationButton} from "../deleteConversationButton/DeleteConversationButton";
import {useUnreadMessagesCount} from "../../../../../common/components/header/messages/hooks/useUnreadMessagesCount";
import {PublicUserInfo} from "../../../../../common/commands/user/types/PublicUserInfo";
import "./styles/selectedConversationRenderer.scss";
import StickyBox from "react-sticky-box";

interface Props {
    conversation: ConversationDecorator | null;
    onUpdate: (conversation: ConversationDecorator) => void;
    onDelete: (conversationId: string) => void;
    users: Record<string, PublicUserInfo>;
}

export const SelectedConversation: FC<Props> = (props: Props) => {
    const [user] = useCurrentUser();
    const [, refresh] = useUnreadMessagesCount();
    const chatBottomElement: LegacyRef<HTMLDivElement> = useRef({} as HTMLDivElement);
    const {conversation, onUpdate, onDelete, users} = props;
    const [message, setMessage] = useState<string>("");
    const handleChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        setMessage(event.currentTarget.value);
    };
    const scrollToBottom = () => {
        chatBottomElement.current?.scrollTo(0, chatBottomElement.current?.scrollHeight)
    };
    const sendMessage = async () => {
        if (!conversation) return;
        try {
            const result: ConversationDecorator = await new AdNewMessageCommand(message).execute(conversation);
            setMessage("");
            onUpdate(result);
        } catch (e) {
            console.log(e);
        }
    };
    const getMessageContent = (item: MessageDecorator, isCurrentUser: boolean) => {
        const userInfo: PublicUserInfo | undefined = users[item.userId];
        const displayName: string = userInfo?.displayName || "Користувач";
        return (
            <div className="selected-conversation__message-content">
                <div className="selected-conversation__message-data">
                    <div className="selected-conversation__message-author">{!isCurrentUser && displayName}</div>
                    <div className="selected-conversation__date">{item.createdOn}</div>
                </div>
                {item.message}
            </div>
        );
    };

    useEffect(() => {
        if (!conversation) return;
        scrollToBottom();
        if (conversation.hasUnreadMessages(user?.id ?? "")) {
            new MarkAsReadConversationCommand().execute(conversation).then((result: ConversationDecorator) => {
                onUpdate(result);
                refresh();
            });
        }
    }, [conversation]);

    if (!conversation) {
        return (
            <Card className="selected-conversation--empty">
                Оберіть повідомлення
            </Card>
        );
    }

    return (
        <StickyBox offsetTop={64} className="selected-conversation-sticky-box">
            <Card className="selected-conversation">
                <DeleteConversationButton conversation={conversation} onDelete={onDelete}/>
                <ConversationInfoRenderer conversation={conversation}/>
                <div className="selected-conversation__chat" ref={chatBottomElement}>
                    <div className="selected-conversation__chat-overflow">
                        {conversation.messages.map((item: MessageDecorator) => {
                            const isCurrentUser: boolean = user?.id === item.src.userId;
                            const className: string = cn("selected-conversation__message", {
                                "selected-conversation__message--current": isCurrentUser
                            });
                            const wrapperClassName: string = cn("selected-conversation__message-wrap", {
                                "selected-conversation__message-wrap--current": isCurrentUser
                            });
                            return (
                                <div className={wrapperClassName} key={item.src.id}>
                                    <Comment content={getMessageContent(item, isCurrentUser)} className={className}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="selected-conversation__new-message">
                    <TextArea rows={1} value={message} onChange={handleChange}/>
                    <Button
                        type={ButtonType.SECONDARY}
                        icon={<SendIcon/>}
                        disabled={isEmpty(message)}
                        onClick={sendMessage}
                    >
                        Надіслати
                    </Button>
                </div>
            </Card>
        </StickyBox>
    );
};