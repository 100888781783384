import React, {FC, useState} from "react";
import {Card, Col, Form, Input, message, Modal, Row, Upload} from "antd";
import {Shop} from "../../../types/integration/Shop";
import {Button, ButtonType} from "../button/Button";
import {PhoneNumberInput} from "../phoneNumberInput/PhoneNumberInput";
import {ReactComponent as DeleteIcon} from "./resources/Delete.svg";
import {ReactComponent as UploadIcon} from "./resources/UploadIcon.svg";
import {ReactComponent as Cross} from "./resources/Cross.svg";
import {ReactComponent as Tick} from "./resources/Tick.svg";
import {ReactComponent as Pencil} from "./resources/Pencil.svg";
import {RuleObject, StoreValue, ValidateErrorEntity} from "rc-field-form/lib/interface";
import {isEmpty} from "lodash-es";
import {UploadFile} from "antd/lib/upload/interface";
import {RemovableImage} from "../images/removableImage/RemovableImage";
import {deleteUser, signOut} from "firebase/auth";
import {auth} from "../../../firebase/config";
import {Pages} from "../../../routing/Pages";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {useNavigate} from "react-router-dom";
import {dummyRequest} from "../../utils/UploadUtils";
import {ACCEPTED_IMAGE_TYPES} from "../../../content/newAdPage/NewAdPage";
import {UploadedImages} from "../images/UploadedImages";
import "./styles/shopForm.scss";
import {LogoutButton, LogoutButtonTypes} from "../header/userProfileMenu/logoutButton/LogoutButton";

export enum ShopFormMode {
    EDIT = "EDIT",
    CREATE = "CREATE"
}

interface Props {
    mode: ShopFormMode;
    onFinish: (values: Shop) => void;
    initialValues?: Shop;
    logoUrl?: string;
}

const lengthValidator = (min: number, max: number) => {
    return (rule: RuleObject, value: StoreValue) => {
        if (value.length < min) {
            return Promise.reject(`Мінімальна довжина ${min}`);
        }

        if (value.length > max) {
            return Promise.reject(`Максимальна довжина ${min}`);
        }
        return Promise.resolve();
    }
};

export const ShopForm: FC<Props> = (props: Props) => {
    const [user, setUpdatedUser] = useCurrentUser();
    const {onFinish, initialValues, mode, logoUrl} = props;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState<boolean>(mode === ShopFormMode.EDIT);
    const [images, setImages] = useState<string[] | undefined>(logoUrl ? [logoUrl] : undefined);

    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const filesValidator = (rule: RuleObject, value: StoreValue) => {
        if (isEmpty(value) && isEmpty(images)) {
            return Promise.reject("Будь ласка, додайте фото");
        }
        return Promise.resolve(null);
    };

    const fileItemRenderer = (originNode: React.ReactElement, file: UploadFile, fileList: Array<UploadFile>, actions: { remove: () => void }) => {
        const {remove} = actions;
        const url = URL.createObjectURL(file.originFileObj as Blob);
        return (<RemovableImage url={url} onClick={remove}/>);
    };

    const deleteAccount = async () => {
        Modal.confirm({
            title: "Ви дійсно бажаєте видалити обліковий запис?",
            onOk: async () => {
                if (user) {
                    try {
                        if (user.firebaseUser) {
                            await deleteUser(user.firebaseUser);
                            setUpdatedUser(null);
                        }
                    } catch (e: any) {
                        if (e.code === "auth/requires-recent-login") {
                            Modal.info({title: "Увійдіть ще раз, щоб видалити обліковий запис."})
                            await signOut(auth);
                        }
                    }
                    navigate(Pages.LANDING);
                }
            }
        });
    };

    const submitForm = () => {
        form.submit();
    };

    const setEditable = () => {
        setDisabled(false);
    };

    const setNotEditable = () => {
        setImages([logoUrl ?? ""]);
        form.resetFields();
        setDisabled(true);
    };

    const saveData = async () => {
        form.submit();
        setDisabled(true);
    };

    const handleFormChange = (values: Shop) => {
        if (values.info.logoUrl) {
            setImages([]);
        }
    };


    const handleFinishFail = (errorInfo: ValidateErrorEntity<Shop>) => {
        showErrorMessage();
        setTimeout(() => {
            const firstIncorrectField = document.getElementsByClassName("ant-form-item-explain-error");
            if (firstIncorrectField[0]) {
                firstIncorrectField[0].scrollIntoView({block: "center"});
            }
        }, 100);
    };

    const showErrorMessage = () => {
        message.error("Будь ласка, заповніть всі обовʼязкові поля", 3);
    };

    return (
        <div className="shop-form">
            <Form<Shop>
                layout="vertical"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                onFinishFailed={handleFinishFail}
                initialValues={initialValues}
                onValuesChange={handleFormChange}
                disabled={disabled}
            >
                {user?.isNotConfirmedBusinessAccount && (
                    <div className="shop-form__header">
                        <h1>Реєстрація Бізнес-Акаунту</h1>
                        <LogoutButton type={LogoutButtonTypes.BUTTON}/>
                    </div>
                )}

                <Card>
                    <Row>
                        <Col span="12">
                            <label>Логотип компанії:</label>
                            {!isEmpty(images) && (
                                <UploadedImages urls={images!} onChange={setImages} disabled={disabled}/>
                            )}

                            {!disabled && (
                                <Form.Item name={["info", "logoUrl"]} getValueFromEvent={getFile}
                                           valuePropName="fileList"
                                           rules={[{validator: filesValidator}]}>
                                    <Upload action="/" customRequest={dummyRequest}
                                            className="shop-form__upload"
                                            beforeUpload={() => false}
                                            multiple={false}
                                            maxCount={1}
                                            itemRender={fileItemRenderer}
                                            showUploadList={{showPreviewIcon: false}}
                                            accept={ACCEPTED_IMAGE_TYPES}>
                                        <Button type={ButtonType.LINK_OUTLINE} icon={<UploadIcon/>}>Завантажити
                                            лого</Button>
                                    </Upload>
                                </Form.Item>
                            )
                            }
                        </Col>
                        {mode === ShopFormMode.EDIT && (
                            <Col span="12">
                                <div className="shop-form__edit-controls">
                                    {disabled ?
                                        (<Button type={ButtonType.LINK_OUTLINE} onClick={setEditable} icon={<Pencil/>}>Редагувати
                                            дані</Button>)
                                        : (
                                            <>
                                                <Button type={ButtonType.LINK_OUTLINE} onClick={setNotEditable}
                                                        icon={<Cross/>}>Скасувати
                                                    Зміни</Button>
                                                <Button type={ButtonType.PRIMARY} onClick={saveData} icon={<Tick/>}>Зберегти
                                                    Зміни</Button>
                                            </>
                                        )
                                    }
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Назва компанії"
                                name={["info", "name"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть назву компанії"
                                }]}
                            >
                                <Input placeholder="Назва компанії"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="3">
                            <Form.Item
                                label="Код ЄДРПОУ"
                                name={["info", "edrpou"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Будь ласка, введіть код ЄДРПОУ"
                                    },
                                    {
                                        validator: lengthValidator(8, 8)
                                    }
                                ]}
                            >
                                <Input placeholder="Код ЄДРПОУ"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Адреса"
                                name={["info", "address", "street"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть адресу"
                                }]}
                            >
                                <Input placeholder="Адреса"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="3">
                            <Form.Item
                                label="Поштовий код"
                                name={["info", "address", "postalCode"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Будь ласка, введіть поштовий код"
                                    },
                                    {
                                        validator: lengthValidator(5, 5)
                                    }
                                ]}
                            >
                                <Input placeholder="Поштовий код" max={8} min={8}/>
                            </Form.Item>
                        </Col>
                        <Col span="8" push="1">
                            <Form.Item
                                label="Населенний пункт"
                                name={["info", "address", "city"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть населенний пункт"
                                }]}
                            >
                                <Input placeholder="Населенний пункт"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Телефон компанії"
                                name={["info", "phoneNumber"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть телефон компанії"
                                }]}
                            >
                                <PhoneNumberInput/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Імʼя та прізвище контактної особи"
                                name={["info", "contactPerson", "person"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть імʼя та прізвище контактної особи"
                                }]}
                            >
                                <Input placeholder="Імʼя та прізвище"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Телефон контактної особи"
                                name={["info", "contactPerson", "phoneNumber"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть телефон контактної особи"
                                }]}
                            >
                                <PhoneNumberInput/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Email контактної особи"
                                name={["info", "contactPerson", "email"]}
                                rules={[{
                                    required: true,
                                    message: "Будь ласка, введіть email контактної особи"
                                }]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <h3>Вибір тарифного плану</h3>
                {/*<Form.Item name="plan">*/}
                {/*    <Plans/>*/}
                {/*</Form.Item>*/}
            </Form>
            <div className="shop-form__controls">
                <Button
                    type={ButtonType.DANGER_OUTLINE}
                    onClick={deleteAccount}
                    icon={<DeleteIcon/>}
                >
                    Скасувати і видалити всі дані
                </Button>
                {mode === ShopFormMode.CREATE && (
                    <Button
                        type={ButtonType.PRIMARY}
                        onClick={submitForm}
                    >
                        Продовжити
                    </Button>
                )}
            </div>
        </div>
    );
};