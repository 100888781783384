import {FC} from "react";
import {Pages} from "../../../../routing/Pages";
import {useNavigateWithFrom} from "../../../hooks/useNavigateWithFrom";
import {useCurrentUser} from "../../../commands/user/useCurrentUser";
import {Button, ButtonType} from "../../button/Button";
import {Dropdown} from "antd";
import {MenuProps} from "antd/es";
import {ReactComponent as ExcelIcon} from "./resources/excel.svg";
import {ReactComponent as UploadIcon} from "./resources/upload.svg";
import {ReactComponent as PlusIcon} from "./resources/plus.svg";
import ExportAdsToExcelCommand from "../../../commands/shop/ExportAdsToExcelCommand";
import {ImportAdsAction} from "../../myAdsView/actions/importAds/ImportAdsAction";

import {isMobile} from "../../screen/Screen";
import {useScreenWidth} from "../../../hooks/useScreenWidth";

export const CreateNewAdButton: FC = () => {
    const [user] = useCurrentUser();
    const width = useScreenWidth();
    const navigate = useNavigateWithFrom();
    const handleClick = () => {
        if (!user) {
            navigate(Pages.LOGIN, Pages.NEW_AD);
        } else {
            navigate(Pages.NEW_AD);
        }
    };

    const handleGoToProfileClick = () => {
        if (!user) {
            navigate(Pages.LOGIN);
        } else {
            navigate(`${Pages.USER_PROFILE}?tab=myAds`);
        }
    };

    const handleDownloadMyAds = async () => {
        await new ExportAdsToExcelCommand().execute();
    };

    const items: MenuProps['items'] = [
        {
            key: "1",
            label: "Нове оголошення",
            icon: <PlusIcon/>,
            onClick: handleClick
        },
        {
            key: "2",
            label: "Експорт оголошень",
            icon: <ExcelIcon/>,
            onClick: handleDownloadMyAds
        },
        {
            key: "3",
            label: <ImportAdsAction/>,
            icon: <UploadIcon/>,
            onClick: handleGoToProfileClick
        }
    ];
    const buttonText: string = "Нове Оголошення";
    if (user?.hasShop) {
        return (
            <Dropdown menu={{items}} trigger={["click"]}>
                <Button
                    type={ButtonType.SECONDARY}
                    icon="+"
                    onClick={(e) => e.preventDefault()}>
                    {buttonText}
                </Button>
            </Dropdown>
        )
    }

    return (<Button type={ButtonType.SECONDARY} icon="+" onClick={handleClick}>{isMobile(width) ? null : buttonText}</Button>);
};