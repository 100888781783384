import {FC, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Pages} from "../../routing/Pages";
import {Badge, Tabs} from "antd";
import {UserProfileTabs} from "./UserProfileTabs";
import {MyAds} from "./content/myAds/MyAds";
import {Conversations} from "./content/conversations/Conversations";
import {Favourites} from "./content/favourites/Favourites";
import {Profile} from "./content/profile/Profile";
import {UserProfileAvatar} from "./userProfileAvatar/UserProfileAvatar";
import {isEmpty} from "lodash-es";
import {useUnreadMessagesCount} from "../../common/components/header/messages/hooks/useUnreadMessagesCount";
import "./styles/userProfilePage.scss";
import {useCurrentUser} from "../../common/commands/user/useCurrentUser";
import {ShopProfile} from "./content/shopProfile/ShopProfile";

export const UserProfilePage: FC = () => {
    const [user] = useCurrentUser();
    const [count] = useUnreadMessagesCount();
    const items = [
        {
            label: "Мої оголошення",
            key: UserProfileTabs.MY_ADS
        },
        {
            label: <Badge size="small" count={count.total} overflowCount={99} color="#FF5722"
                          offset={[10, 12]}>Повідомлення</Badge>,
            key: UserProfileTabs.MESSAGES
        },
        {
            label: "Улюблені",
            key: UserProfileTabs.FAVOURITES
        }
    ];

    if (user?.hasShop) {
        items.push({
            label: "Профіль магазину",
            key: UserProfileTabs.SHOP
        })
    } else {
        items.push({
            label: "Мій профіль",
            key: UserProfileTabs.PROFILE
        })
    }

    const [searchParams] = useSearchParams();
    const tab: string = searchParams.get("tab") ?? "";
    const navigate = useNavigate();

    const handleChange = (activeKey: string) => {
        navigate(`${Pages.USER_PROFILE}?tab=${activeKey}`);
    };

    const getContent = () => {
        switch (tab) {
            case UserProfileTabs.MY_ADS:
                return (<MyAds/>);
            case UserProfileTabs.MESSAGES:
                return (<Conversations/>);
            case UserProfileTabs.FAVOURITES:
                return (<Favourites/>);
            case UserProfileTabs.PROFILE:
                return (<Profile/>);
            case UserProfileTabs.SHOP:
                return (<ShopProfile/>);
            default:
                return null;
        }
    };

    useEffect(() => {
        if (isEmpty(tab)) {
            navigate(`${Pages.USER_PROFILE}?tab=${UserProfileTabs.PROFILE}`);
        }
    }, []);

    return (
        <div className="user-profile-page">
            <div className="user-profile-page__title">
                <h1>{user?.hasShop ? "Сторінка Магазину" : "Сторінка Користувача"}</h1>
                <UserProfileAvatar/>
            </div>
            <Tabs items={items} onChange={handleChange} activeKey={tab}/>
            <div className="user-profile-page__content">
                {getContent()}
            </div>
        </div>
    )
};