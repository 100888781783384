import {FC, useEffect, useState} from "react";
import {Carousel, Spin} from "antd";
import {generatePath, useNavigate} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import GetPartnersPublicInfoCommand from "./commands/GetPartnersPublicInfoCommand";
import {isEmpty} from "lodash-es";
import {PublicPartnerPageInfo} from "../../../types/integration/Partners";
import {Screen} from "../screen/Screen";
import {useScreenWidth} from "../../hooks/useScreenWidth";
import "./styles/partnersCarousel.scss";

const getSlidesToShow = (screenWidth: number, size: number) => {
    if (Screen.sm(screenWidth)) {
        return 2 > size ? size : 2;
    }

    if (Screen.xs(screenWidth)) {
        return 1 > size ? size : 1;
    }

    return 3 > size ? size : 3;
};
const slidesToScroll = (slidesToShow: number, size: number): number => {
    if (size === 0) {
        return 0;
    }
    if (size % slidesToShow === 0) {
        return slidesToShow;
    }
    return size % slidesToShow;
};

export const PartnersCarousel: FC = () => {
    const navigate = useNavigate();
    const [partners, setPartners] = useState<PublicPartnerPageInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const goToPartnerDetailsPage = (partnerId: string) => {
        navigate(generatePath(Pages.PARTNER_DETAILS, {partnerId}))
    };
    const screenWidth = useScreenWidth();
    const loadPartnersPublicInfo = async () => {
        try {
            const partners: PublicPartnerPageInfo[] = await new GetPartnersPublicInfoCommand().execute();
            setPartners(partners);
        } finally {
            setLoading(false);
        }
    };

    const slidesToShow = getSlidesToShow(screenWidth, partners.length);

    useEffect(() => {
        loadPartnersPublicInfo();
    }, []);

    if (isEmpty(partners)) {
        return null;
    }

    return (
        <Spin spinning={loading} wrapperClassName="partners-carousel">
            <Carousel slidesToShow={getSlidesToShow(screenWidth, partners.length)}
                      slidesToScroll={slidesToScroll(slidesToShow, partners.length)}>
                {partners.map((item: PublicPartnerPageInfo) => {
                    const {images, name, description} = item;
                    const [image = ""] = images || [];
                    const handleClick = () => {
                        goToPartnerDetailsPage(item.id);
                    };
                    return (
                        <div className="partners-carousel__item" key={item.id}>
                            <div className="partners-carousel__inner" onClick={handleClick}>
                                <div className="partners-carousel__image">
                                    <img src={image} alt={name}/>
                                </div>
                                <div className="partners-carousel__info">
                                    <h6>
                                        {name}
                                    </h6>
                                    <p>
                                        {description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </Spin>
    );
};