import {FC} from "react";
import {ReactComponent as UserIcon} from "./resources/UserIcon.svg";
import {Avatar} from "../../../common/components/header/avatar/Avatar";
import {useCurrentUser} from "../../../common/commands/user/useCurrentUser";
import {
    LogoutButton,
    LogoutButtonTypes
} from "../../../common/components/header/userProfileMenu/logoutButton/LogoutButton";
import "./styles/userProfileAvatar.scss";

export const UserProfileAvatar: FC = () => {
    const [user] = useCurrentUser();

    return (
        <div className="user-profile-avatar">
            <div className="user-profile-avatar__image">
                <Avatar icon={<UserIcon/>} size="large"/>
            </div>
            {user?.displayName && (
                <div className="user-profile-avatar__display-name">
                    {user.displayName}
                </div>
            )}
            <LogoutButton type={LogoutButtonTypes.BUTTON} className="user-profile-avatar__log-out"/>
        </div>
    );
};