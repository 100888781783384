import {FC, ReactNode, useState} from "react";
import {Modal, Spin, Upload} from "antd";
import {RcFile, UploadChangeParam} from "antd/lib/upload/interface";
import ImportAdsFromExcelCommand from "../../../../commands/shop/ImportAdsFromExcelCommand";
import {Button, ButtonType} from "../../../button/Button";
import moment from "moment/moment";
import ValidateAdsFileCommand, {ValidationState} from "../../../../commands/shop/ValidateAdsFileCommand";
import {ReactComponent as WarnIcon} from "./resources/Warn.svg";
import {ReactComponent as ExcelIcon} from "./resources/Excel.svg";
import {ReactComponent as New} from "./resources/New.svg";
import {ReactComponent as Total} from "./resources/Total.svg";
import {ReactComponent as Updated} from "./resources/Updated.svg";
import ExportAdsToExcelCommand from "../../../../commands/shop/ExportAdsToExcelCommand";
import DownloadTemplateCommand from "../../../../commands/shop/DownloadTemplateCommand";
import {downloadBlob} from "../../../../utils/FileUtils";
import "./styles/importAdsAction.scss";

interface Props {
    buttonType?: ButtonType;
    icon?: ReactNode;
}

type CustomRequestOptions = {
    file: string | Blob | RcFile;
    onSuccess?: (body: any) => void
}

const DATE_FORMAT: string = "YYYY-MM-DD";

const TEMPLATE_NAME: string = "baron-template.xlsx";

export const ADS_UPLOADED_EVENT: string = "ADS_UPLOADED";

export const ImportAdsAction: FC<Props> = (props: Props) => {
    const {buttonType, icon} = props;
    const [open, setOpen] = useState<boolean>(false);
    const [file, setFile] = useState<RcFile | null>(null);
    const [validatedFile, setValidatedFile] = useState<Blob | null>(null);
    const [validationState, setValidationState] = useState<ValidationState | null>(null);
    const [isLoading, setLoading] = useState(false);
    const handleUpload = async () => {
        try {
            await new ImportAdsFromExcelCommand().execute(file as RcFile);
            window.dispatchEvent(new Event(ADS_UPLOADED_EVENT));
            hideModal();
        } catch (e) {
            console.warn(e);
        }
        return Promise.resolve();
    };

    const handleValidate = async ({file, onSuccess}: CustomRequestOptions) => {
        setFile(file as RcFile);
        try {
            const result = await new ValidateAdsFileCommand().execute(file as RcFile);
            if (result instanceof Blob) {
                setValidatedFile(result);
            } else {
                setValidationState(result ?? {} as ValidationState)
            }
        } catch (e) {
            console.warn(e);
        }
        onSuccess?.("ok");
        return Promise.resolve();
    };

    const downloadValidateFile = async () => {
        if (!validatedFile) return;
        downloadBlob(validatedFile, getErrorFileDisplayName());
    };

    const resetState = () => {
        setFile(null);
        setValidatedFile(null);
        setValidationState(null);
    };

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        setOpen(false);
        resetState();
    };

    const getErrorFileDisplayName = () => {
        return `Baron-${moment(new Date()).format(DATE_FORMAT)}-errors.xlsx`;
    };

    const getModalTitle = () => {
        const title: ReactNode = validatedFile
            ? (<><WarnIcon/>Помилка Імпорту</>)
            : "Імпорт Оголошень";
        return (<div className="import-ads-action__modal-title">{title}</div>);
    };


    const downloadTemplate = async () => {
        await new DownloadTemplateCommand().execute();
    };

    const exportAds = async () => {
        await new ExportAdsToExcelCommand().execute();
    };

    const getModalContent = () => {
        if (validatedFile) {
            return (
                <div>
                    Завантажений файл <strong>{file?.name}</strong> містить помилки.
                    Щоб їх передивитись та виправити, будь
                    ласка скачайте наступний файл. Після виправлення повторіть операцію імпортування:
                    <a className="import-ads-action__file-with-errors" onClick={downloadValidateFile}>
                        Файл із виправленнями:
                        <ExcelIcon/>
                        {getErrorFileDisplayName()}
                    </a>
                </div>
            )
        }

        if (validationState) {
            const {inserts, updates} = validationState;
            const total: number = inserts + updates;
            return (
                <div className="import-ads-action__validation-results">
                    Завантажений файл <strong>{file?.name}</strong> містить:
                    <ul>
                        <li><Total/>всього оголошень: <b>{total}</b></li>
                        <li><New/>нових оголошень, що будуть додані: <b>{inserts}</b></li>
                        <li><Updated/>оголошень, що замінять вже існуючі:<b className="red">{updates}</b></li>
                    </ul>
                    <p>Цю операцію не можна буде відмінити. Рекомендовано зберегти копію існуючої бази оголошень для
                        відновлення в разі необхідності:</p>
                    <a onClick={exportAds}>Існуюча база оголошень:
                        Baron-{moment(new Date()).format(DATE_FORMAT)}.xlsx</a>
                </div>
            );
        }

        return (
            <Spin spinning={isLoading} size="large">
                <div className="import-ads-action__default">
                    Для коректного імпорту Ваших оголошень, скористайтесь файлом-шаблоном:
                    <ol>
                        <li>Скачайте файл-шаблон бази оголошень: <a onClick={downloadTemplate}><ExcelIcon/>{TEMPLATE_NAME} </a></li>
                        <li>Заповніть його своїми оголошеннями, зберігаючи формат шаблону</li>
                        <li>Імпортуйте готовий файл з оголошеннями</li>
                    </ol>
                </div>
            </Spin>
        );
    };

    const getModalFooter = () => {
        let buttons: ReactNode
        if (validatedFile) {
            buttons = (<Button onClick={hideModal} type={ButtonType.PRIMARY}>Зрозуміло</Button>);
        } else if (validationState) {
            buttons = (
                <>
                    <Button onClick={hideModal} type={ButtonType.OUTLINE}>Відмінити</Button>
                    <Button onClick={handleUpload}>Імпортувати Оголошення</Button>
                </>
            );
        } else {
            buttons = (
                <>
                    <Button disabled={isLoading} onClick={hideModal} type={ButtonType.OUTLINE}>Відмінити</Button>
                    <Upload disabled={isLoading} onChange={onFileChange} customRequest={handleValidate} showUploadList={false}
                            accept=".xlsx, .xls">
                        <Button>Імпорт Готового Файлу з Оголошеннями</Button>
                    </Upload>
                </>
            );
        }
        return (<div className="import-ads-action__modal-footer">{buttons}</div>);
    };

    const onFileChange = (params: UploadChangeParam) => {
        if (params.file.status === "uploading") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            {
                buttonType
                    ? (
                        <Button
                            type={buttonType}
                            icon={icon}
                            onClick={showModal}
                        >
                            Імпорт оголошень
                        </Button>
                    )
                    : (<span onClick={showModal}>Імпорт оголошень</span>)
            }
            <Modal
                className="import-ads-action__modal"
                width={640}
                open={open}
                onCancel={hideModal}
                title={getModalTitle()}
                footer={getModalFooter()}
            >
                {getModalContent()}
            </Modal>
        </>
    );
};