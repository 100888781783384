import {FC, useEffect, useState} from "react";
import AdvertisementDecorator from "../../../../types/decorators/AdvertisementDecorator";
import {Status} from "../../../adDetailsPage/commands/types/Advertisement";
import {SegmentedValue} from "rc-segmented";
import {GetUserAdsCountCommand} from "./commands/GetUserAdsCountCommand";
import ArchiveAdsCommand from "../../../../common/commands/ad/ArchiveAdsCommand";
import DeleteAdsCommand from "../../../../common/commands/ad/DeleteAdsCommand";
import {useCurrentUser} from "../../../../common/commands/user/useCurrentUser";
import SearchForAuthorCommand from "../../../../common/commands/search/SearchForAuthorCommand";
import {AuthorSort} from "../../../../types/integration/SearchDto";
import {MyAdsView} from "../../../../common/components/myAdsView/MyAdsView";
import "./styles/myAds.scss";
import {ADS_UPLOADED_EVENT} from "../../../../common/components/myAdsView/actions/importAds/ImportAdsAction";

const PAGE_SIZE: number = 20;

export const MyAds: FC = () => {
    const [user] = useCurrentUser();
    const initialAdsCount: Record<Status, number> = {
        [Status.ACTIVE]: 0,
        [Status.DRAFT]: 0,
        [Status.DECLINED]: 0,
        [Status.ARCHIVED]: 0,
    };
    const [advertisements, setAdvertisements] = useState<AdvertisementDecorator[]>([]);
    const [adsCount, setAdsCount] = useState<Record<Status, number>>(initialAdsCount);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedAds, setSelectedAds] = useState<string[]>([]);
    const [segmentedValue, setSegmentedValue] = useState<Status>(Status.ACTIVE);
    const [nextPage, setNextPage] = useState<string | undefined>();
    const search = async (nextPageToLoad: string | undefined = undefined) => {
        if (!user) {
            return;
        }
        setLoading(true);
        try {
            await new SearchForAuthorCommand().withNexPage(nextPageToLoad).execute({
                status: segmentedValue,
                limit: PAGE_SIZE,
                sort: AuthorSort.EXPIRATION_ASC,
                tags: [{shop: [""]}],
                userId: user?.id ?? ""
            }).then(({items, nextPage}) => {
                if (nextPageToLoad) {
                    setAdvertisements([...advertisements, ...items]);
                } else {
                    setAdvertisements(items);
                }
                setNextPage(nextPage);
            });
            await new GetUserAdsCountCommand().execute(user.id ?? "").then(setAdsCount);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = async (value: SegmentedValue) => {
        setNextPage(undefined);
        setSegmentedValue(value as Status);
        setSelectedAds([]);
    };

    const handleSelect = (selectedAds: string[]) => {
        setSelectedAds(selectedAds);
    };

    const handleLoadMore = async () => {
        await search(nextPage);
    };

    useEffect(() => {
        search(undefined);
    }, [segmentedValue, user]);

    useEffect(() => {
        const listener = async () => {
            await refreshArchivedAd();
        };
        window.addEventListener(ADS_UPLOADED_EVENT, listener);
        return () => {
            window.removeEventListener(ADS_UPLOADED_EVENT, listener);
        };
    }, []);

    const makeNotActive = async () => {
        if (segmentedValue === Status.ARCHIVED) {
            await new DeleteAdsCommand().execute(selectedAds).then(() => {
                setSelectedAds([]);
                search();
            });
        } else {
            await new ArchiveAdsCommand().execute(selectedAds).then(() => {
                setSelectedAds([]);
                search();
            });
        }
    }

    const archiveAdInReview = async () => {
        setSelectedAds([]);
        await search();
    }

    const refreshArchivedAd = async () => {
        setSelectedAds([]);
        await search(undefined);
    }

    return (
        <MyAdsView
            adsCount={adsCount}
            advertisements={advertisements}
            isLoading={isLoading}
            statusValue={segmentedValue}
            onStatusChange={handleStatusChange}
            selectedAds={selectedAds}
            onSelectAd={handleSelect}
            onDelete={makeNotActive}
            onLoadMore={handleLoadMore}
            nextPage={nextPage}
            pageSize={PAGE_SIZE}
            onArchive={archiveAdInReview}
            onRefresh={refreshArchivedAd}
        />
    );
};