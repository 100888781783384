import {FC, RefObject, useEffect, useRef, useState} from "react";
import {Card, Col, Form, Input, message, Row, Select, Spin, Upload} from "antd";
import {ReactComponent as UploadLogo} from "./resources/UploadLogo.svg";
import {ReactComponent as GlassesLogo} from "./resources/Glasses.svg";
import {ReactComponent as TickLogo} from "./resources/Tick.svg";
import {UploadFile} from "antd/lib/upload/interface";
import CreateNewAdCommand from "./commands/CreateNewAdCommand";
import {Navigate, useNavigate} from "react-router-dom";
import {Pages} from "../../routing/Pages";
import {UserProfileTabs} from "../userProfilePage/UserProfileTabs";
import {ImageUploadCommand} from "./commands/ImageUploadCommand";
import {PhoneNumberInput, phoneNumberValidator} from "../../common/components/phoneNumberInput/PhoneNumberInput";
import {getCategories} from "./CategoriesMenuBuilder";
import {SingleValueType} from "rc-cascader/lib/Cascader";
import {isEmpty, omit} from "lodash-es";
import {useCatalog} from "../../common/commands/catalog/useCatalog";
import {NumericInput} from "../../common/components/numericInput/NumericInput";
import {getPickList} from "../../common/staticData/StaticData";
import {UploadedImages} from "../../common/components/images/UploadedImages";
import {AdPreview} from "../preview/AdPreview";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {RuleObject, StoreValue, ValidateErrorEntity} from "rc-field-form/lib/interface";
import UpdateAddCommand from "./commands/UpdateAddCommand";
import {useCurrentUser} from "../../common/commands/user/useCurrentUser";
import {Button, ButtonType} from "../../common/components/button/Button";
import {ReactComponent as Decline} from "../moderationPage/resources/Decline.svg";
import {CategoriesSelect} from "../../common/components/categoriesSelect/CategoriesSelect";
import {dummyRequest} from "../../common/utils/UploadUtils";
import "./styles/newAdPage.scss";
import {useScreenWidth} from "../../common/hooks/useScreenWidth";
import {isMobile} from "../../common/components/screen/Screen";

const {TextArea} = Input;

interface FormValues extends Record<string, string | number | undefined | string[] | UploadFile[]> {
    title: string;
    category: string[];
    description?: string;
    price?: number;
    files: UploadFile[];
}

export const ACCEPTED_IMAGE_TYPES: string = ".jpeg, .jpg, .png, .gif";

interface Props {
    adId?: string | undefined;
    details?: Record<string, string | string[] | undefined>;
    images?: string[];
}

export const NewAdPage: FC<Props> = (props: Props) => {
    const [user] = useCurrentUser();
    const [form] = Form.useForm();
    const fileUploadElement: RefObject<HTMLDivElement | undefined> = useRef<HTMLDivElement | undefined>();
    const {
        adId, details = {
            email: user?.src?.email ?? "",
            phoneNumber: user?.src?.phoneNumber ?? "",
        },
        images
    } = props;
    const width = useScreenWidth();
    const mobile = isMobile(width);
    const [catalog] = useCatalog();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<string[]>(details?.category as string[] ?? []);
    const [uploadedImages, setUploadedImages] = useState<string[] | undefined>(images);
    const navigate = useNavigate();
    const [isPreviewMode, setPreviewMode] = useState<boolean>(false);

    const showErrorMessage = () => {
        message.error("Будь ласка, заповніть всі обовʼязкові поля", 3);
    };

    const handleFinish = async (values: FormValues) => {
        const {
            files,
            ...rest
        } = values;
        setLoading(true);
        try {
            if (adId) {
                const images: string[] = [...(uploadedImages ?? [])];
                if (files) {
                    const newImages: string[] = await new ImageUploadCommand(user!).execute(files);
                    images.push(...newImages);
                }
                const ad = {...rest, images};
                await new UpdateAddCommand().execute({adId, ad})
            } else {
                const images: string[] = await new ImageUploadCommand(user!).execute(files);
                const ad = {...rest, images};
                await new CreateNewAdCommand().execute(ad);
            }
            navigate(`${Pages.USER_PROFILE}?tab=${UserProfileTabs.MY_ADS}`);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (value: SingleValueType) => {
        setSelectedCategory(value as string[]);
    };

    if (!user) {
        return (<Navigate to={Pages.LANDING}/>);
    }

    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleBack = () => {
        setPreviewMode(false);
        window.scrollTo(0, 0);
    };

    const handlePreview = async () => {
        form.validateFields().then(() => {
            setPreviewMode(true);
            window.scrollTo(0, 0);
        }).catch(() => {
            showErrorMessage();
        });
    }

    const handleCancel = () => {
        navigate("/");
    }

    const getPreviewAd = (): AdvertisementDecorator => {
        const formValues = form.getFieldsValue();
        const ad = omit(formValues, ["files", "category"]);
        return new AdvertisementDecorator(ad);
    };

    const newImages: string[] = (form.getFieldValue("files") ?? []).map((item: UploadFile) => URL.createObjectURL(item.originFileObj as Blob));

    const filesValidator = (rule: RuleObject, value: StoreValue) => {
        if (isEmpty(value) && isEmpty(uploadedImages)) {
            return Promise.reject("Будь ласка, додайте фото");
        }
        return Promise.resolve(null);
    };

    const handleFinishFail = (errorInfo: ValidateErrorEntity<FormValues>) => {
        showErrorMessage();
        setTimeout(() => {
            const firstIncorrectField = document.getElementsByClassName("ant-form-item-explain-error");
            if (firstIncorrectField[0]) {
                firstIncorrectField[0].scrollIntoView({block: "center"});
            }
        }, 100);
    };

    const handleSubmitAfterPreview = () => {
        const formValues = form.getFieldsValue();
        return async () => {
            await handleFinish(formValues);
        }
    };

    let characteristics: Record<string, []> | undefined = undefined;
    if (!isEmpty(selectedCategory)) {
        characteristics = catalog?.getCharacteristics(selectedCategory as string[]);
    }

    return (
        <Spin spinning={loading} wrapperClassName="new-add-page__spin">
            {isPreviewMode && (
                <AdPreview advertisement={getPreviewAd()} onBack={handleBack}
                           onPublish={handleSubmitAfterPreview()}
                           uploadedImages={[...newImages, ...(uploadedImages || [])]}/>
            )}
            {!isPreviewMode && (
                <div className="new-add-page">
                    <h1>{adId ? "Редагування оголошення" : "Нове Оголошення"}</h1>
                    <Form<FormValues>
                        name="createAd"
                        onFinish={handleFinish}
                        onFinishFailed={handleFinishFail}
                        layout="vertical"
                        initialValues={details}
                        form={form}
                    >
                        <Card title="Назва та Категорія" bordered={false}>
                            <Row>
                                <Col span={mobile ? "24" : "12"}>
                                    <Form.Item
                                        label="Назва предмету продажу"
                                        name="title"
                                        requiredMark="optional"
                                        rules={[{
                                            required: true,
                                            message: "Будь ласка, введіть назву предмету продажу"
                                        }]}
                                    >
                                        <Input placeholder="Наприклад, Помпова рушниця Mossberg 590"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={mobile ? "24" : "12"}>
                                    <Form.Item
                                        label="Категорія"
                                        name="category"
                                        requiredMark="optional"
                                        rules={[{required: true, message: "Будь ласка, оберіть категорію"}]}
                                    >
                                        <CategoriesSelect options={getCategories(catalog?.src ?? {})}
                                                          onChange={handleCategoryChange} mode="single"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card title="Фото" className="new-add-page__photo"
                              ref={fileUploadElement as RefObject<HTMLDivElement>}>
                            {uploadedImages && (
                                <UploadedImages urls={uploadedImages} onChange={setUploadedImages}/>
                            )}
                            <Form.Item name="files" getValueFromEvent={getFile}
                                       valuePropName="fileList"
                                       rules={[{validator: filesValidator}]}>
                                <Upload listType="picture-card" action="/" customRequest={dummyRequest}
                                        beforeUpload={() => false}
                                        multiple={true}
                                        showUploadList={{showPreviewIcon: false}}
                                        accept={ACCEPTED_IMAGE_TYPES}>
                                    <div className="new-add-page__upload-logo">
                                        <UploadLogo/>
                                        <p>Додати Фото</p>
                                    </div>
                                </Upload>
                            </Form.Item>
                        </Card>
                        {!isEmpty(selectedCategory) && characteristics && (
                            <Card title="Характеристики">
                                {
                                    Object.keys(characteristics).map((key: string) =>
                                        (
                                            <Row key={key}>
                                                <Col span={mobile ? "24" : "12"}>
                                                    <Form.Item
                                                        name={key}
                                                        label={key}
                                                        requiredMark="optional"
                                                        rules={[{
                                                            required: true,
                                                            message: "Будь ласка, оберіть один з варіантів"
                                                        }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            options={(characteristics ? characteristics[key] : []).map((value: string) => ({
                                                                value,
                                                                key: value
                                                            }))}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                }
                            </Card>
                        )}
                        <Card title="Опис">
                            <Row>
                                <Col span="24">
                                    <Form.Item name="description"
                                               rules={[{required: true, message: "Будь ласка, додайте опис"}]}>
                                        <TextArea
                                            autoSize={{minRows: 4}}
                                            showCount
                                            maxLength={5000}
                                            placeholder="Додайте детальний опис предмету продажу"
                                        />

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={mobile ? "12" : "4"}>
                                    <Form.Item
                                        name="price"
                                        label="Ціна, грн"
                                        requiredMark="optional"
                                        rules={[{required: true, message: "Будь ласка, додайте ціну"}]}
                                    >
                                        <NumericInput placeholder="Ціна"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card title="Контакти">
                            <Row>
                                <Col span={mobile ? "24" : "6"}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{required: true, message: "Будь ласка, додайте email", type: "email"}]}
                                    >
                                        <Input placeholder="Email"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={mobile ? "20" : "6"}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label="Номер телефону"
                                        validateTrigger={["onSubmit", "onChange"]}
                                        rules={[{validator: phoneNumberValidator}]}
                                    >
                                        <PhoneNumberInput/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={mobile ? "20" : "6"}>
                                    <Form.Item
                                        name="location"
                                        label="Місце знаходження"
                                        requiredMark="optional"
                                        rules={[{required: true, message: "Будь ласка, оберить область"}]}
                                    >
                                        <Select options={getPickList("region")} placeholder="Область"
                                                dropdownMatchSelectWidth={false} allowClear={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Row className="new-add-page__buttons">
                            <Col span={mobile ? "24" : "16"}>
                                <Button type={ButtonType.DANGER_OUTLINE}
                                        onClick={handleCancel} icon={<Decline/>}>
                                    Скасувати Оголошення
                                </Button>
                            </Col>
                            <Col span={mobile ? "24" : "5"}>
                                <Button type={ButtonType.OUTLINE}
                                        onClick={handlePreview} icon={<GlassesLogo/>}>
                                    Попередній Перегляд
                                </Button>
                            </Col>
                            <Col span={mobile ? "24" : "3"}>
                                <Form.Item>
                                    <Button type={ButtonType.PRIMARY} icon={<TickLogo/>} buttonType="submit">
                                        Опублікувати
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Spin>
    );
};