export const downloadBlob = (blob: Blob, filename: string) => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const decodeRFC2047 = (encodedStr: string) => {
    return encodedStr
        .replace(/=\?UTF-8\?Q\?(.*?)\?=/gi, (match, p1) => {
            return decodeURIComponent(p1.replace(/_/g, ' ').replace(/=([A-Fa-f0-9]{2})/g, '%$1'));
        });
}
export const getFileNameFromContentDisposition = (response: Response) => {
    const contentDisposition = response.headers.get('Content-Disposition');
    const filenameRegex = /filename\*?=([^;]+)(?:; filename\*?=([^;]+))?/i;
    const match = contentDisposition?.match(filenameRegex);

    let filename = 'downloaded-file.xlsx';
    if (match) {
        if (match[2]) {
            filename = decodeURIComponent(match[2].replace(/UTF-8''/, ''));
        } else {
            filename = decodeRFC2047(match[1].trim());
        }
    }
    return filename;
}
