import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";
import {RcFile} from "antd/lib/upload/interface";

export default class ImportAdsFromExcelCommand extends FetchCommand<any, any> {
    async execute(file: RcFile) {
        const body: FormData = new FormData();
        body.append("file", file, file.name);
        return api<any>("/api/batch/upload", {
            method: "PUT",
            body,
        })
    }
}