import {FC} from "react";
import {ReactComponent as Refresh} from "./resources/refresh.svg";
import cn from "classnames";
import "./styles/loadMoreButton.scss";

interface Props {
    onClick: () => void;
    text: string;
    className?: string;
}

export const LoadMoreButton: FC<Props> = (props: Props) => {
    const {onClick, text, className} = props;
    return (
        <div className={cn("load-more", className)}>
            <div className="load-more__button" onClick={onClick}>
                <Refresh/> {text}
            </div>
        </div>
    )
}