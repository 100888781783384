import {FC} from "react";
import {Col, Image, Row} from "antd";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {generatePath, Link, useNavigate} from "react-router-dom";
import {Pages} from "../../../../../routing/Pages";
import "./styles/conversationInfoRenderer.scss";
import {useScreenWidth} from "../../../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../../../common/components/screen/Screen";

interface Props {
    conversation: ConversationDecorator;
    authorDisplayName?: string;
}

export const ConversationInfoRenderer: FC<Props> = (props: Props) => {
    const {conversation, authorDisplayName} = props;
    const {advertisement} = conversation;
    const [thumbnail] = advertisement?.thumbnails ?? [];
    const to: string = generatePath(Pages.AD_PUBLIC_DETAILS, {adId: advertisement?.id ?? ""});
    const navigate = useNavigate();
    const width = useScreenWidth();
    const handleClick = () => {
        if (isMobile(width)) {
            navigate(generatePath(Pages.CONVERSATION_DETAILS, {conversationId: conversation.id}), {
                state: {conversation: conversation.src, advertisement: advertisement?.src}
            });
        }
    };
    return (
        <Row className="conversation-info-renderer" onClick={handleClick}>
            <Col span="6" className="conversation-info-renderer__ad-image">
                {!isMobile(width)
                    ? (
                        <Link to={to}>
                            <Image src={thumbnail} preview={false}/>
                        </Link>
                    )
                    : (<Image src={thumbnail} preview={false}/>)
                }

            </Col>
            <Col span="18" className="conversation-info-renderer__ad-info">
                <p className="conversation-info-renderer__date">
                    {!isMobile(width)
                        ? (
                            <Link to={to}>
                                {advertisement?.createdOn}
                                {authorDisplayName && (
                                    <>
                                        {" - "}
                                        <strong>{authorDisplayName}</strong>
                                    </>
                                )}
                            </Link>
                        )
                        : (
                            <>
                                {advertisement?.createdOn}
                                {authorDisplayName && (
                                    <>
                                        {" - "}
                                        <strong>{authorDisplayName}</strong>
                                    </>
                                )}
                            </>
                        )}
                </p>
                <p className="conversation-info-renderer__title">
                    {!isMobile(width)
                        ? (
                            <Link to={to}>
                                {advertisement?.createdOn}
                                {authorDisplayName && (
                                    <>
                                        {" - "}
                                        <strong>{authorDisplayName}</strong>
                                    </>
                                )}
                            </Link>
                        )
                        : (
                            <>
                                {advertisement?.title}
                            </>
                        )}
                </p>
                <p className="conversation-info-renderer__price">
                    {
                        !isMobile(width)
                            ? (
                                <Link to={to}>
                                    <strong>{advertisement?.price}</strong>
                                </Link>
                            )
                            : (
                                <strong>{advertisement?.price}</strong>
                            )
                    }
                </p>
            </Col>
        </Row>
    )
};
