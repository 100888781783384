import {FC, useEffect, useState} from "react";
import GetConversationsCommand from "./commands/GetConversationsCommand";
import {ConversationDecorator} from "../../../../types/decorators/ConversationDecorator";
import {Badge, Col, Row, Segmented, Spin} from "antd";
import {ConversationItem} from "./conversationItem/ConversationItem";
import {SelectedConversation} from "./selectedConversation/SelectedConversation";
import {PublicUserInfo} from "../../../../common/commands/user/types/PublicUserInfo";
import {GetPublicUserInfoCommand} from "../../../../common/commands/user/GetPublicUserInfoCommand";
import {arrayToMap} from "../../../../common/utils/ArrayUtils";
import {useCurrentUser} from "../../../../common/commands/user/useCurrentUser";
import "./styles/conversation.scss";
import {SegmentedValue} from "rc-segmented";
import {isEmpty} from "lodash-es";
import {LoadMoreButton} from "../../../../common/components/loadMoreButton/LoadMoreButton";
import {EmptyText} from "../../../../common/components/emptyText/EmptyText";
import {useUnreadMessagesCount} from "../../../../common/components/header/messages/hooks/useUnreadMessagesCount";
import {useScreenWidth} from "../../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../../common/components/screen/Screen";

enum ConversationsType {
    MINE = "MINE",
    OTHER = "OTHER"
}

export const Conversations: FC = () => {
    const [user] = useCurrentUser();
    const [count] = useUnreadMessagesCount();
    const [conversations, setConversations] = useState<ConversationDecorator[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<ConversationDecorator | null>(null);
    const [users, setUsers] = useState<Record<string, PublicUserInfo>>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [conversationType, setConversationType] = useState<ConversationsType>(ConversationsType.OTHER);
    const [nextPage, setNextPage] = useState<string | undefined>();
    const width = useScreenWidth();
    const span: string = isMobile(width) ? "24" : "12";
    const options = [
        {
            label: <Badge size="small" count={count.other} color="#FF5722">Чужі оголошення</Badge>,
            value: ConversationsType.OTHER,
        },
        {
            label: <Badge size="small" count={count.mine} color="#FF5722">Мої оголошення </Badge>,
            value: ConversationsType.MINE,
        },
    ];
    const handleTypeChange = (value: SegmentedValue) => {
        setNextPage(undefined);
        setConversations([]);
        setSelectedConversation(null);
        setConversationType(value as ConversationsType);
    };

    const getAuthorIds = (conversations: ConversationDecorator[]): string[] => {
        return conversations.reduce((result: string[], item: ConversationDecorator) => {
            const {authorId = ""} = item.advertisement?.src ?? {};
            if (!users[authorId]) {
                result.push(authorId);
            }
            return result;
        }, []);
    };

    const loadData = async () => {
        try {
            setLoading(true);
            const {items: loadedConversations, nextPage: page} = await new GetConversationsCommand()
                .withNextPage(nextPage)
                .forInitiator(conversationType !== ConversationsType.MINE).execute();
            const authorIds: string[] = getAuthorIds(loadedConversations);
            if (!isEmpty(authorIds)) {
                const loadedUsers: Record<string, PublicUserInfo> = await new GetPublicUserInfoCommand()
                    .execute(authorIds)
                    .then(arrayToMap<PublicUserInfo>);
                setUsers({...users, ...loadedUsers});
            }

            setConversations([...conversations, ...loadedConversations]);
            setNextPage(page);
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (conversation: ConversationDecorator) => {
        setSelectedConversation(conversation);
    };

    const handleUpdate = (conversation: ConversationDecorator) => {
        setSelectedConversation(conversation);
        setConversations(conversations.map((item: ConversationDecorator) => {
            if (item.id === conversation.id) {
                return conversation;
            }
            return item;
        }));
    };

    const handleDelete = (conversationId: string) => {
        setConversations(conversations.filter((item: ConversationDecorator) => item.id !== conversationId));
        if (selectedConversation?.id === conversationId) {
            setSelectedConversation(null);
        }
    };

    useEffect(() => {
        loadData();
    }, [conversationType]);

    return (
        <Spin spinning={loading} wrapperClassName="conversations">
            <Row>
                <Segmented options={options} onChange={handleTypeChange} value={conversationType}/>
            </Row>
            {
                isEmpty(conversations)
                    ? (
                        <EmptyText text="У вас ще немає повідомлень"/>
                    )
                    : (
                        <Row className="conversations__wrapper">
                            <Col span={span} className="conversations__list">
                                {conversations.map((item: ConversationDecorator) => {
                                    const authorId: string = item.advertisement?.src.authorId ?? "";
                                    const authorDisplayName: string | undefined = authorId !== user?.id ? users[authorId].displayName : undefined;
                                    return (
                                        <ConversationItem
                                            key={item.id} conversation={item} onSelect={handleSelect}
                                            selected={selectedConversation?.id === item.id}
                                            authorDisplayName={authorDisplayName}
                                            onDelete={handleDelete}
                                        />)
                                })}
                                {nextPage && (
                                    <LoadMoreButton onClick={loadData} text="Показати ще"/>
                                )}
                            </Col>
                            {!isMobile(width) && (
                                <Col span={12} className="conversations__selected-item">
                                    {!isEmpty(conversations) && (
                                        <SelectedConversation conversation={selectedConversation} onUpdate={handleUpdate}
                                                              users={users}
                                                              onDelete={handleDelete}/>
                                    )}
                                </Col>
                            )}
                        </Row>
                    )
            }
        </Spin>
    );
};