import FetchCommand from "../../fetch/FetchCommand";
import {RcFile} from "antd/lib/upload/interface";
import {api} from "../../fetch/api";

export interface ValidationState {
    inserts: number;
    updates: number
}
export default class ValidateAdsFileCommand extends FetchCommand<ValidationState | Blob | undefined, RcFile> {
    fileName: string | undefined;

    withFileName(fileName: string) {
        this.fileName = fileName;
        return this;
    }

    async execute(file: RcFile) {
        try {
            const body: FormData = new FormData();
            body.append("file", file, file.name)
            const response: Response | ValidationState = await api("/api/batch/validate", {method: "PUT", body});
            if (response instanceof Response) {
                return await response.blob();
            }
            return response as ValidationState;
        } catch (error) {
            console.error('Error:', error);
            this.showError({message: "Помилка імпорту"})
            return Promise.reject({});
        }
    }
}